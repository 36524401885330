<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card :loading="isLoading">
          <v-card-title>
            <v-row>
              <v-col>Rendszerüzenetek</v-col>
              <v-col class="text-right">
                <v-btn color="primary" small @click="createResource">Új rendszerüzenet</v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <SystemMessagesTable />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'

import { GET_SYSTEM_MESSAGES_REQUEST } from '@/store/types'

import SystemMessagesTable from '@/components/SystemMessages/SystemMessagesTable.vue'

export default {
  name: 'PageSystemMessages',
  components: {
    SystemMessagesTable
  },
  computed: {
    ...mapState({
      status: state => state.systemMessages.status
    }),
    isLoading() {
      return this.status === 'loading'
    }
  },
  created() {
    this.$store.dispatch(GET_SYSTEM_MESSAGES_REQUEST)
  },
  methods: {
    createResource() {
      this.$modal.show('system-message-dialog', {
        isNewResource: true
      })
    }
  }
}
</script>
