<template>
  <div>
    <v-data-table v-if="!isLoading" :headers="headers" :items="resources">
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon size="20" color="black" class="mr-3" v-on="on" @click="editResource(item)">mdi-pencil</v-icon>
          </template>
          Módosítás
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon size="20" color="black" v-on="on" @click="openDeleteConfirmator(item)">mdi-delete</v-icon>
          </template>
          Törlés
        </v-tooltip>
      </template>
    </v-data-table>

    <v-skeleton-loader type="table" v-else></v-skeleton-loader>
    <ConfirmDialog :confirm-handler="deleteConfirmHandler"></ConfirmDialog>
    <SystemMessageDialog></SystemMessageDialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import { DELETE_SYSTEM_MESSAGE } from '@/store/types'

const ConfirmDialog = () => import('@/components/Global/ConfirmDialog.vue')
const SystemMessageDialog = () => import('@/components/SystemMessages/SystemMessageDialog.vue')

export default {
  name: 'SystemMessagesTable',
  components: {
    ConfirmDialog,
    SystemMessageDialog
  },
  data() {
    return {
      headers: [
        {
          text: '#',
          sortable: false,
          value: 'id'
        },
        {
          text: 'Név',
          align: 'start',
          value: 'name'
        },
        {
          text: '',
          value: 'actions',
          align: 'end',
          sortable: false
        }
      ],
      deleteConfirmHandler: {}
    }
  },
  computed: {
    ...mapState({
      status: state => state.systemMessages.status,
      resources: state => state.systemMessages.resources
    }),
    isLoading() {
      return this.status === 'loading'
    }
  },
  methods: {
    openDeleteConfirmator(resource) {
      this.deleteConfirmHandler = {
        handler: () => this.deleteResource(resource),
        title: `${resource.name} rendszerüzenet törlése`
      }
      this.$modal.show('confirm-dialog')
    },
    async deleteResource(resource) {
      await this.$store.dispatch(DELETE_SYSTEM_MESSAGE, resource.id)
      this.$modal.hide('confirm-dialog')
    },
    editResource(resource) {
      this.$modal.show('system-message-dialog', {
        resource,
        isNewResource: false
      })
    }
  }
}
</script>
